import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Button, TextField } from '@mui/material';
import { useState, useEffect } from 'react';
import axios from 'axios'; // Import axios

// Custom hook to track window size
function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
}

const columns = [
  { field: 'id', headerName: 'No', width: 120 },
  { field: 'dateCreated', headerName: 'Date Created', width: 180 },
  { field: 'timeCreated', headerName: 'Time Created', width: 150 },
  { field: 'name', headerName: 'Nama', width: 180 },
  { field: 'nominal', headerName: 'Nominal', width: 180 },
  { field: 'username', headerName: 'Username', width: 180 },
];

export default function TransactionTable() {
  const { width } = useWindowSize(); // Get window width dynamically
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]); // State to track selected row IDs
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState({
    dateCreated: '',
    timeCreated: '',
    name: '',
    nominal: '',
    username: '',
  });

  // Fungsi logout
  const logout = () => {
    localStorage.removeItem('token');
    window.location.href = '/login'; // Redirect to login page
  };

  // Fetch records from API
const fetchRecords = async () => {
  try {
    const response = await axios.get('https://panelbete.live/api/get_transactions.php');
    setRows(response.data); // Set initial rows with data from the server
    setFilteredRows(response.data); // Set initial filtered rows
  } catch (error) {
    console.error('Error fetching records:', error);
  }
};


  // Fetch records on component mount
  useEffect(() => {
    fetchRecords();
  }, []);

  // Filter rows based on search input
  useEffect(() => {
    if (searchTerm === '') {
      setFilteredRows(rows);
    } else {
      const lowercasedSearchTerm = searchTerm.toLowerCase();
      const filtered = rows.filter((row) =>
        row.name.toLowerCase().includes(lowercasedSearchTerm) ||
        row.username.toLowerCase().includes(lowercasedSearchTerm) ||
        row.nominal.toString().includes(lowercasedSearchTerm)
      );
      setFilteredRows(filtered);
    }
  }, [searchTerm, rows]);

  // Handle input changes for filters
  const handleFilterChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Add new record
  // Add new record
const addRecord = async () => {
  if (!filters.dateCreated || !filters.timeCreated || !filters.name || !filters.nominal || !filters.username) {
    alert('All fields must be filled to add a record.');
    return;
  }

  const newRecord = {
    dateCreated: filters.dateCreated,
    timeCreated: filters.timeCreated,
    name: filters.name,
    nominal: filters.nominal,
    username: filters.username,
  };

  try {
    // Kirim data ke backend PHP
    const response = await axios.post('https://panelbete.live/api/add_transaction.php', newRecord);
    // Tambahkan data baru ke state setelah berhasil disimpan di server
    setRows((prevRows) => [...prevRows, response.data]);
    setFilteredRows((prevRows) => [...prevRows, response.data]);

    // Reset form input
    setFilters({
      dateCreated: '',
      timeCreated: '',
      name: '',
      nominal: '',
      username: ''
    });
  } catch (error) {
    console.error('Error adding record:', error);
    alert('Failed to add record');
  }
};

  // Handle delete record
  const deleteRecord = async () => {
    try {
      if (selectedRows.length === 0) {
        alert('No records selected to delete.');
        return;
      }
      
      // Remove the selected rows by filtering out their IDs
      const remainingRows = rows.filter((row) => !selectedRows.includes(row.id));
      setRows(remainingRows);
      setFilteredRows(remainingRows); // Also update the filtered rows
      setSelectedRows([]); // Clear the selected rows
    } catch (error) {
      console.error('Error deleting record:', error);
      alert('Failed to delete record.');
    }
  };

  // Export to CSV function
  const exportToCSV = () => {
    if (rows.length === 0) {
      alert('No data available to export.');
      return;
    }

    const csvData = rows.map(row => ({
      No: row.id,
      'Date Created': row.dateCreated,
      'Time Created': row.timeCreated,
      'Nama': row.name,
      'Nominal': row.nominal,
      'Username': row.username
    }));

    const csvContent = [
      Object.keys(csvData[0]).join(','),
      ...csvData.map(row => Object.values(row).join(','))
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'transaction_data.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div style={{ margin: '20px', padding: '10px', backgroundColor: '#F9F9F9', borderRadius: '8px' }}>
      {/* Search Bar */}
      <TextField
        label="Search"
        value={searchTerm}
        onChange={handleSearchChange}
        placeholder="Search by Nama, Username, or Nominal"
        style={{ marginBottom: '20px', width: '100%' }}
      />

      {/* Filters and Buttons in the Same Row */}
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px', flexWrap: 'wrap' }}>
        {/* Filter Section */}
        <div style={{ display: 'flex', gap: '15px', flexWrap: 'wrap', width: width > 600 ? '60%' : '100%' }}>
          <TextField
            label="Date Created"
            name="dateCreated"
            type="date"
            value={filters.dateCreated}
            onChange={handleFilterChange}
            InputLabelProps={{ shrink: true }}
            style={{ width: width > 600 ? '180px' : '48%' }}
          />
          <TextField
            label="Time Created"
            name="timeCreated"
            type="time"
            value={filters.timeCreated}
            onChange={handleFilterChange}
            InputLabelProps={{ shrink: true }}
            style={{ width: width > 600 ? '180px' : '48%' }}
          />
          <TextField
            label="Nama"
            name="name"
            value={filters.name}
            onChange={handleFilterChange}
            style={{ width: width > 600 ? '180px' : '48%' }}
          />
          <TextField
            label="Nominal"
            name="nominal"
            value={filters.nominal}
            onChange={handleFilterChange}
            style={{ width: width > 600 ? '180px' : '48%' }}
          />
          <TextField
            label="Username"
            name="username"
            value={filters.username}
            onChange={handleFilterChange}
            style={{ width: width > 600 ? '180px' : '48%' }}
          />
        </div>

        {/* Button Section */}
        <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap', width: width > 600 ? 'auto' : '100%', justifyContent: 'space-between' }}>
          <Button
            variant="contained"
            style={{ backgroundColor: '#1976D2', color: 'white', padding: '10px 20px', fontWeight: 'bold', flexGrow: width > 600 ? 0 : 1 }}
            onClick={addRecord}
          >
            ADD RECORD
          </Button>
          <Button
            variant="contained"
            style={{
              backgroundColor: selectedRows.length === 0 ? '#C4C4C4' : '#D32F2F',
              color: 'white',
              padding: '10px 20px',
              fontWeight: 'bold',
              flexGrow: width > 600 ? 0 : 1,
            }}
            onClick={deleteRecord}
            disabled={selectedRows.length === 0}
          >
            DELETE RECORD
          </Button>
          <Button
            variant="outlined"
            style={{ borderColor: '#4CAF50', color: '#4CAF50', padding: '10px 20px', fontWeight: 'bold', flexGrow: width > 600 ? 0 : 1 }}
            onClick={exportToCSV}
          >
            EXPORT TO CSV
          </Button>
          {/* Tombol Logout dipindahkan ke sini */}
          <Button
            variant="contained"
            style={{ backgroundColor: '#D32F2F', color: 'white', padding: '10px 20px', fontWeight: 'bold' }}
            onClick={logout}
          >
            LOGOUT
          </Button>
        </div>
      </div>

      {/* Data Grid */}
      <div style={{ height: '50vh', width: '100%' }}>
        <DataGrid
          rows={filteredRows}
          columns={columns}
          pageSize={5}
          checkboxSelection
          onSelectionModelChange={(newSelectionModel) => {
            setSelectedRows(Array.from(newSelectionModel)); // Store selected row IDs
          }}
          selectionModel={selectedRows}
          disableSelectionOnClick
        />
      </div>
    </div>
  );
}
