import './App.css';
import TransactionTable from './components/TransactionTable'; // Pastikan path ke komponen benar
import logo from './components/assets/wldata.png'; // Sesuaikan path ke lokasi gambar
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Login from './components/login';
import PrivateRoute from './components/PrivateRoute';

function App() {
  const [token, setToken] = useState(localStorage.getItem('token'));

  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <img src={logo} alt="Logo" className="logo" /> {/* Tambahkan logo */}
          <h1 className="header-title-right">Transaction Records</h1>
        </header>

        {/* Routing untuk komponen */}
        <main>
          <Switch>
            <Route path="/login">
              <Login setToken={setToken} />
            </Route>
            
            {/* Private Route untuk transaksi */}
            <PrivateRoute
              path="/transactions"
              component={TransactionTable}
              token={token}
            />

            {/* Redirection jika rute tidak ada */}
            <Redirect to="/login" />
          </Switch>
        </main>
      </div>
    </Router>
  );
}

export default App;
