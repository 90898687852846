import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import './login.css'; // Tambahkan file CSS untuk styling

function Login({ setToken }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false); // State untuk kontrol visibilitas password
  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();

    // Hardcode username dan password yang diizinkan
    if (username === 'admin' && password === 'admin') {
      const token = 'dummy-token'; // Bisa digunakan token dummy
      localStorage.setItem('token', token); // Simpan token di localStorage
      setToken(token);
      history.push('/transactions'); // Arahkan ke halaman transaksi
    } else {
      alert('Login gagal, username atau password salah');
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <h2>Login</h2>
        <form onSubmit={handleSubmit}>
          <div>
            <label>Username:</label>
            <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
          </div>
          <div>
            <label>Password:</label>
            <div className="password-wrapper">
              <input
                type={showPassword ? 'text' : 'password'} // Tipe berubah berdasarkan state
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <span
                className="password-toggle-icon"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? '🙈' : '🙉'} {/* Ganti dengan ikon mata */}
              </span>
            </div>
          </div>
          <button type="submit">Login</button>
        </form>
      </div>
    </div>
  );
}

export default Login;
